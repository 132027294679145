<template>
  <va-card
    class="media-item"
    :image="getImage()"
    :title="$t(getType(resource.type))"
  >
    <template v-slot:actions>
      <va-popover
        v-if="owner && currentUser.can(permission, 'edit')"
        :message="$t('tables.actions.edit')"
        placement="up"
      >
        <va-button
          flat
          small
          color="warning"
          icon="fa fa-edit"
          @click.prevent="edit"
        />
      </va-popover>
      <va-popover
        v-if="owner && currentUser.can(permission, 'delete')"
        :message="$t('tables.actions.delete')"
        placement="up"
      >
        <va-button
          flat
          small
          color="danger"
          icon="fa fa-trash-o"
          @click.prevent="trash"
        />
      </va-popover>
      <va-popover
        :message="$t('tables.actions.share')"
        placement="up"
      >
        <va-button
          flat
          small
          color="primary"
          icon="fa fa-share-alt"
          @click.prevent="share"
        />
      </va-popover>
      <va-popover
        :message="$t('tables.actions.download')"
        placement="up"
      >
        <va-button
          flat
          small
          color="success"
          icon="fa fa-external-link"
          @click.prevent="download"
        />
      </va-popover>
    </template>
    <article class="card-article">
      <h4>{{ resource.name }}</h4>
      <p>{{ resource.description }}</p>
      <small v-if="resource.filesize">{{ $t('tables.headings.size') }}: {{ resource.filesize | byterize }}</small>
      <slot></slot>
    </article>
  </va-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'media-card',
  props: {
    owner: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
      default: '',
    },
    resource: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    isDocx () {
      return this.resource.type === 0
    },
    isExcel () {
      return this.resource.type === 1
    },
    isPdf () {
      return this.resource.type === 2
    },
    isImage () {
      return this.resource.type === 3
    },
    isVideo () {
      return this.resource.type === 4
    },
    isAudio () {
      return this.resource.type === 5
    },
  },
  methods: {
    getImage () {
      let preview = ''
      if (this.resource.preview && this.resource.preview.length > 0) {
        preview = this.getPath(this.resource.preview)
      } else {
        preview = this.getPreview(this.resource.path)
      }

      return preview
    },
    getPath (site) {
      if (/^https?:\/\/[^/]+/g.test(site)) {
        return site
      }

      let base = this.$http.defaults.baseURL.slice(0)
      if (base) {
        if (/\/api\//g.test(base)) {
          base = base.replace('/api/', '')
        }
        if (!/^https?:\/\/[^/]+/g.test(base)) {
          base = window.origin + base
        }
        site = base + site
      }

      return site
    },
    getPreview (path) {
      let src = '/static/unknown_resource.png'
      if (this.isImage) {
        src = this.getPath(path)
      } else if (this.isVideo) {
        src = '/static/video-player.png'
      } else if (this.isAudio) {
        src = '/static/audio.png'
      } else if (this.isDocx) {
        src = '/static/word.png'
      } else if (this.isExcel) {
        src = '/static/excel.png'
      } else if (this.isPdf) {
        src = '/static/pdf.png'
      }

      return src
    },
    getType (type) {
      let t = 'tables.undefined'
      switch (type) {
        case 0: t = 'resources.types.docx'; break
        case 1: t = 'resources.types.xlsx'; break
        case 2: t = 'resources.types.pdf'; break
        case 3: t = 'resources.types.jpg'; break
        case 4: t = 'resources.types.mp4'; break
        case 5: t = 'resources.types.mp3'; break
        case 7: t = 'resources.types.pptx'; break
        default: t = 'resources.types.unknown'; break
      }

      return t
    },
    download () {
      const path = this.getPath(this.resource.path)
      this.$emit('download', path)
    },
    edit () {
      this.$emit('edit', this.resource.id)
    },
    trash () {
      this.$emit('trash', this.resource)
    },
    share () {
      const path = this.getPath(this.resource.path)
      this.$emit('share', path)
    },
  },
}
</script>
<style>
.media-item .va-card__image {
  max-height: 50%;
  min-height: auto;
}
</style>
